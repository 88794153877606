import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React, { lazy, Suspense, useEffect } from "react";
import { css } from "styled-components/macro"; //eslint-disable-line


import { Routes, Route } from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";
import ReactGA from "react-ga";
import { Helmet, HelmetProvider } from "react-helmet-async";
import GlobalLoader from "components/GlobalLoader";
const LandingPage = lazy(() => import("./pages/LandingPage"));
const About = lazy(() => import("./pages/AboutUs"));
const Contact = lazy(() => import("./pages/ContactUs"));
const Business = lazy(() => import("./pages/BusinessPartners"));
const Provider = lazy(() => import("./pages/HealthcareProviders"));
const Patients = lazy(() => import("./pages/Patients"));
const Articles = lazy(() => import("./pages/Articles"));
const TeamProfile = lazy(() => import("./pages/TeamProfile"));
const Article = lazy(() => import("./pages/Article"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Terms = lazy(() => import("./pages/Terms"));
const ContactUS = lazy(() => import("./pages/Contact"));


// import LandingPage from "pages/LandingPage";
// import About from 'pages/AboutUs'
// import Contact from 'pages/ContactUs'
// import Business from "pages/BusinessPartners";
// import Provider from "pages/HealthcareProviders"

// import Patients from "pages/Patients";
// import Articles from "pages/Articles";
// import TeamProfile from "pages/TeamProfile";
// import Article from "pages/Article";
// import Privacy from "pages/Privacy";
// import Terms from "pages/Terms";

//const TRACKING_ID = "G-1G1VLQMZRB";
const TRACKING_ID = "G-K2F1KSRXVS";
ReactGA.initialize(TRACKING_ID);

export default function App() {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
    console.log(ReactGA, window.location.pathname + window.location.search);
  });

  return (
    <Suspense fallback={<GlobalLoader/>}>
      <HelmetProvider>
        <Helmet>
          <title>Libero Health</title>
          <meta name="description" content="Libero Health is a patient centred connected health platform to provide near real time connected health services to all our patients, healthcare providers and business partners on a single platform." />
          <meta name="keywords" content="Libero Health, Connected health platform, Patient centred; Patient centric, Patient centred connected health" />
        </Helmet>
        <ScrollToTop>
          <Routes>

            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/business-partner" element={<Business />} />
            <Route path="/healthcare-provider" element={<Provider />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/patient" element={<Patients />} />
            <Route path="/articles" element={<Articles />} />
            <Route path="/profile" element={<TeamProfile />} />
            <Route path="/article" element={<Article />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/contactUs" element={<ContactUS/>}/>

          </Routes>
        </ScrollToTop>
      </HelmetProvider>
    </Suspense>
  );
}