
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import logo from "../images/icon.png";

export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0 
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-primary-500 text-gray-100
  hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-10 mr-3 `}
  }
`;


const GlobalLoader = () => {

    return (<>
        <LogoLink to="/" style={{justifyContent:'center',paddingTop:'20%'}}>
            <img src={logo} alt="logo" />
            <p style={{ display: 'block', marginTop: 5 }}><span style={{ display: 'block' }}>LIBERO HEALTH </span>
                <span style={{ display: 'block', fontSize: 12, color: "#0097da", fontWeight: 'lighter' }}>Your Connected Health</span></p>
        </LogoLink>
    </>)

}

export default GlobalLoader;